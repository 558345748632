@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  color: rgb(62,69,67);
}
h1, h2, h3, h4, h5, h6 {
  font-variant: small-caps;
  letter-spacing: 1px;
}

a {
  color: rgb(0,120,140);
}
a:hover {
  color: rgb(98,167,68)
}
.navbar-brand {
  width: 150px;
}
.jumbotron {
  margin-bottom: 0;
}
.img-responsive {
  max-width: 100%;
}
.bg-danger {
  background-color: #F2DEE0 !important;
  border: 3px solid #dc3545;
  border-left: 0;
  border-right: 0;
}
.bg-success {
  background-color: #d4edda !important;
  border: 3px solid #c3e6cb;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}
.jumbotron {
  background-image: url("scott-graham-5fNmWej4tAA-unsplash_25pct.jpg");
  background-size: cover;
}
.text-small {
  font-size: 0.9em;
}
.text-big {
  font-size: 1.2em;
}
.blue-line {
  color: rgb(0,120,140);
}
#contact {
  background-color: rgba(144,156,156, 0.5);
}
.contact-info {
  background: rgb(255,255,255);
  background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}
#practice-areas .container .row div div {
  padding: 12px;
  border-radius: 10px;
}

#practice-areas .container .row div:nth-child(odd) div {
  background: rgba(0,120,140, 0.25);
}
#practice-areas .container .row div:nth-child(even) div {
  background: rgba(98,167,68, 0.25);
}
#our-team .container-fluid .row:nth-child(even) img {
  border: 5px solid rgb(0,120,140);
}
#our-team .container-fluid .row:nth-child(odd) img {
  border: 5px solid rgb(98,167,68);
}

#our-team .container-fluid .row:nth-child(even) {
  background: rgb(255,255,255);
  background: -moz-linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,120,140,0.5) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,120,140,0.5) 100%);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,120,140,0.5) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#00788c",GradientType=1);
}

#our-team .container-fluid .row:nth-child(odd) {
  background: rgb(255,255,255);
  background: -moz-linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(98,167,68,0.5) 100%);
  background: -webkit-linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(98,167,68,0.5) 100%);
  background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(98,167,68,0.5) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#62a744",GradientType=1);
}


@media (min-width: 576px) { }

@media (min-width: 768px) {
  h1 {
    font-size: 3.2em;
  }
  h2 {
    font-size: 3em;
  }
  h3 {
    font-size: 2.3em;
  }
  h4 {
    font-size: 1.5em;
  }
  h5 {
    font-size: 1.2em;
  }
}

@media (min-width: 992px) { }

@media (min-width: 1200px) { }